import React from 'react';
import './storyMageAnimation.css';
import BackgroundImage from './storyMageBackground.jpg'
import Image from './storyMageFairytaleImg.jpg'
import Fireflies from "./Fireflies/Fireflies";
import ChatBot from "./Bot/Chatbot";

const StoryMageAnimation = () => {
    return (
        <div style={{overflow: "hidden"}}>
            <div className="story-mage-background-image-container">
                <p className='fairytale-text'>Deep in the forest, a dark and </p>
                <p className='fairytale-text delayed-text'> foreboding presence lurks...</p>

                <img className="story-mage-background-image" src={BackgroundImage}
                     alt="a wolf appears from the trees to track little red robin hood"/>
                <img className="story-mage-image  story-image-fade-out" src={Image}
                     alt="a wolf appears from the trees to track little red robin hood"/>
                <ChatBot
                    position={"robot-home-position"}
                    scale={"robot robot-scale-home"}
                    gradient={"robot-radial-gradient"}
                    robotList={"robot-list-none"}
                />
                <p className="what-if-text">What if you could write the stories <br/>you always wanted to read?</p>
                <p className="bot-text">Welcome to The Story Mage! I'm your AI writing assistant.  I can help you write unforgettable plots, settings, characters- you name it!
                </p>

            </div>

            <Fireflies/>

        </div>
    )
}
export default StoryMageAnimation