import React from 'react';
import FadeInSection from "../FadeInSection";

const RecentProject = ({skill1, skill2, skill3, skill4, text, projectTitle, video}) => {
    return (
        <>
            <FadeInSection>
                <div className='recent-project-container'>
                    <div className='recent-skills'><p>{skill1}<br/>{skill2}<br/>{skill3}<br/>{skill4}</p></div>
                    <div className='recent-video'>
                        {video}
                    </div>
                    <div className='recent-project-description'>
                        <p className='recent-project-title'>{projectTitle}</p>
                        <p className='project-description-text'>{text} </p></div>
                </div>
            </FadeInSection>
        </>
    )
}

export default RecentProject