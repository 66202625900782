import './App.css';

import Home from './Home'
import About from './components/About/About'
import ASUPage from './pages/ASUPage'
import AtHomePage from './pages/AtHomePage'
import CrystalPage from './pages/CrystalPage'
import DrupalPage from './pages/DrupalPage'
import PoetryPage from './pages/PoetryPage'
import SpringwaterPage from './pages/SpringwaterPage'
import TheStoryMagePage from './pages/TheStoryMagePage'

import {BrowserRouter, Routes, Route} from "react-router-dom";

function App() {
    return (
        <BrowserRouter>
            <div className="App">
                <Routes>
                    <Route path="/" exact element={<Home/>}/>
                    <Route path="/about" exact element={<About/>}/>
                    <Route path="/asu" exact element={<ASUPage/>}/>
                    <Route path="/athomemiddleschool" exact element={<AtHomePage/>}/>
                    <Route path="/crystal" exact element={<CrystalPage/>}/>
                    <Route path="/drupal" exact element={<DrupalPage/>}/>
                    <Route path="/poetry" exact element={<PoetryPage/>}/>
                    <Route path="/springwater" exact element={<SpringwaterPage/>}/>
                    <Route path="/thestorymage" exact element={<TheStoryMagePage/>}/>

                </Routes>
            </div>
        </BrowserRouter>
    );
}


export default App;
