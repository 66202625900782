import React, {useRef, useEffect} from 'react';
import {Link} from "react-router-dom";
import {AnimatedBg, Transition} from "scroll-background";
import './App.css'
import RecentProject from "./components/RecentProjects/RecentProject";
import Video from './components/videos/Video'
import StoryMageAnimation from "./components/RecentProjects/TheStoryMage/StoryMageAnimation";
import Poetry from "./components/RecentProjects/Poetry/Poetry";
import PastProjectsHome from "./components/PastProjects/PastProjects"
import downarrow from './components/images/downarrow.svg'
import video from './components/videos/athomemiddlevideo.mp4'
import FadeInSection from "./components/FadeInSection";


const Home = () => {

    const scroll = useRef(null);
    const handleClick = () => {
        scroll.current?.scrollIntoView({behavior: 'smooth'});
    };
    const scroll2 = useRef(null);
    const handleClick2 = () => {
        scroll2.current?.scrollIntoView({behavior: 'smooth'});
    };
    const handleClickTop = () => {
        scroll2.current?.scrollIntoView({behavior: 'smooth'});
    };

    return (
        <>
            <AnimatedBg>
                <section className="">
                    <header>

                        {/*<Link to="/about" reloadDocument={true}><a*/}
                        {/*    className="about-link fade-in-text">About</a></Link>*/}
                    </header>
                    <p className="site-title fade-in-text">Simple Design</p>
                    <button className="hidden-btn" onClick={handleClick}><img className="arrow" src={downarrow}/>
                    </button>
                </section>

                <section className="">
                    <div ref={scroll}/>
                    <FadeInSection>
                        <div style={{height: '100vh', margin: 'auto'}}>
                            <p className="my-intro">I'm Melissa Caldwell Brown, a web developer with a keen
                                interest in contributing to the world through code and design.</p>
                            <button className="hidden-btn" onClick={handleClick2}><img alt="" className="arrow"
                                                                                       src={downarrow}/>
                            </button>
                        </div>

                    </FadeInSection></section>

                <Transition height="250px" from="#0c0c0d" to="#fff"/>


                <section className=" max-width-projects">
                    <div ref={scroll2}/>
                    <FadeInSection>
                        <p className="recent-projects">Recent Projects</p>
                        <div className="align-projects" style={{height: "76vh"}}>
                            <div className='recent-projects-container'>
                                {/*<Link to="/athomemiddleschool" reloadDocument={true}>*/}
                                <a href="https://www.athomemiddleschool.com/"
                                   target='_blank'
                                   rel="noopener">
                                    <RecentProject
                                        projectTitle="At Home Middle School"
                                        skill1="Gatsby"
                                        skill2="React"
                                        skill3="Design"
                                        skill4="Content"
                                        text="At Home Middle School provides free curriculum, organized into 180 daily lessons for grades 6-8. Content includes courses in: math, language arts, social studies, science, coding, Spanish, art history, and chess."
                                        video={<Video video={video}/>}
                                    />
                                </a>
                                {/*</Link>*/}
                            </div>
                        </div>
                    </FadeInSection>
                </section>

                <section className=" max-width-projects">
                    <div className="align-projects">
                        <div className='recent-projects-container'>
                            {/*<Link to="/thestorymage" reloadDocument={true}>*/}
                            <a href="https://www.thestorymage.com/"
                               target='_blank'
                               rel="noopener">
                                <RecentProject
                                    projectTitle="The Story Mage"
                                    skill1="GPT-3"
                                    skill2="React"
                                    skill3="Design"
                                    skill4="Content"
                                    text="Powered by OpenAI's GPT-3 technology, The Story Mage helps users write creative and original stories.  A bot helps them get started and moves their story along when they get stuck."
                                    video={<StoryMageAnimation/>}
                                />
                            </a>
                            {/*</Link>*/}
                        </div>
                    </div>
                </section>

                <section className=" max-width-projects">
                    <div className="align-projects">
                        <div className='recent-projects-container'>
                            {/*<Link to="/poetry" reloadDocument={true}>*/}
                            <a href="https://www.poetry.athomemiddleschool.com/"
                               target='_blank'
                               rel="noopener">
                                <RecentProject
                                    projectTitle="100 Days of Poetry"
                                    skill1="ePub"
                                    skill2="React"
                                    skill3="Design"
                                    skill4="Content"
                                    text="An eBook of one hundred famous poems with their meanings explained. A continuous line drawing accompanies each poem."
                                    video={<Poetry/>}
                                /></a>
                            {/*</Link>*/}
                        </div>
                    </div>
                </section>


                <section className="" style={{height: '100vh'}}>
                    <div className='recent-projects-container' style={{paddingTop: "20vh"}}>
                        <PastProjectsHome/>
                        <div className="back-to-top">
                            <button className="hidden-btn home-btn-text" onClick={handleClickTop}>Back
                                to Projects
                            </button>
                        </div>
                    </div>
                </section>
            </AnimatedBg>
        </>
    );
}

export default Home