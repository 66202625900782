import React from "react";
import "./chatbot.css";


function ChatBot({position, scale, gradient, robotText, instructions}) {



    return (
        <>

                <div className={position}>
                    <div className={gradient}>
                        <div className={scale}>
                            <div className="circle-bg">
                                <div className="robot-ear left"/>
                                <div className="robot-head">
                                    <div className="robot-face">
                                        <div className="eyes left"/>
                                        <div className="eyes right"/>
                                        <div className="mouth"/>
                                    </div>
                                </div>
                                <div className="robot-ear right"/>
                                <div className="robot-body"/>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    );
}

export default ChatBot;
