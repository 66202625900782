import React from 'react';
import PagesTemplate from "./PagesTemplate/PagesTemplate";
import image from "../components/images/pastProjects/crystalPages.jpg"
import video from "../components/videos/crystal.mp4"
import testimonialImage from "../components/images/pastProjects/crystaltestimonial.png"

const CrystalPage = () => {

    return (

        <>
            <PagesTemplate
                image1={image}
                projectTitle={"Crystal Remodeling"}
                description={"The owners of Crystal Remodeling wanted their site to convey high-end construction. There is a concise description of their company, which allows the pastProjects to do most of the talking. The layout is simple and muted background colors allow the photos to take precedence."}
                link={'https://www.crystal.simpledzn.com/'}
                video={video}
                testimonialImage={testimonialImage}
                testimonial={"\"After settling with a poorly designed, outdated website for too long we reached out to Melissa for help with a much-needed upgrade. She provided us with a clean design, and a streamlined interface. Within days of launch we had multiple former customers contact us with compliments on the new site. It’s reassuring to know when future customers visit our site they’ll have the same impression, as we now have a site which accurately displays the quality our craftsmen work so hard to achieve.\""}
                testimonialName={"Derek Brown, Owner, Crystal Remodeling"}
                previous={"/asu"}
                previousSite={"Previous Site"}
                // next={"/athomemiddleschool"}
            />
        </>

    )
}

export default CrystalPage;