import React from 'react';
import PagesTemplate from "./PagesTemplate/PagesTemplate";
import image from "../components/images/pastProjects/springwaterPages.jpg"
import video from "../components/videos/springwater.mp4"
import testimonialImage from "../components/images/pastProjects/springwatertestimonial.png"
import image2 from "../components/images/pastProjects/springRoster.jpg"

const SpringwaterPage = () => {

    return (

        <>
            <PagesTemplate
                image1={image}
                projectTitle={"Springwater Preschool"}
                description={"Springwater Preschool is a fun and caring place where children learn a love for the outdoors. The director, Stephanie Dodge, wanted her site to give viewers an authentic feel for this. Stephanie also wanted her site to be playful, which was accomplished through the use of bright colors, engaging photos and animation."}
                link={'https://springwater.simpledzn.com/'}
                video={video}
                testimonialImage={testimonialImage}
                testimonial={"\"Melissa redesigned my web presence to be a more vibrant and engaging introduction to my preschool program. She flawlessly combined my wants and needs with her creativity and knowledge of web design to create a custom website that invites prospective customers in to learn more about our program. She created a site that reflects the joy and wonder of learning we work to foster at our school and it feels like a very good representation of who we are. Melissa was easy to work with, professional and prompt in her responses. From the initial sharing of ideas to the gathering of information through to the publication of the site, Melissa kept the project moving and her work more than met my expectations!\""}
                testimonialName={"Stephanie Dodge, Director, Springwater Preschool"}
                style={"pages-project-wide"}
                image2={image2}
                style2={"pages-wide-image"}
                style3={"wide-pages-project-description"}
                description2={"Using ASP.NET allowed for a dynamic site where parents could register their children online. Class rosters could then be sorted and displayed based on name, age, program or any SQL column category."}
                previous={"/drupal"}
                next={"/asu"}
                previousSite={"Previous Site"}
                nextSite={"Next Site"}
            />
        </>

    )
}

export default SpringwaterPage;