import React from 'react';
import LazyLoad from 'react-lazyload';
import './video.css'

const Video = ({video}) => {

    return (
        <div className="videoMargins">

            <LazyLoad className="videoWrapper">
                <video autoPlay muted loop width="100% !important">
                    <source src={video} type="video/mp4" />
                </video>
            </LazyLoad>
        </div>
    )
};
// Example taken from https://github.com/twobin/react-lazyload

export default Video