import React, {useRef, useState} from "react";
import './about.css';
import {Link} from "react-router-dom";
import video from '../videos/about.mp4'


function About() {

    const videoRef = useRef(null);
    const [playing, setPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [videoTime, setVideoTime] = useState(0);
    const [progress, setProgress] = useState(0);

    const videoHandler = (control) => {
        if (control === "play") {
            videoRef.current.play();
            setPlaying(true);
            var vid = document.getElementById("video1");
            setVideoTime(vid.duration);
        } else if (control === "pause") {
            videoRef.current.pause();
            setPlaying(false);
        }
    };

    const fastForward = () => {
        videoRef.current.currentTime += 5;
    };

    const revert = () => {
        videoRef.current.currentTime -= 5;
    };

    window.setInterval(function () {
        setCurrentTime(videoRef.current?.currentTime);
        setProgress((videoRef.current?.currentTime / videoTime) * 100);
    }, 1000);

    return (
        <>
            {/*<header className="about-header">*/}
            {/*    <Link to={"/"} reloadDocument={true}><a*/}
            {/*        className="about-link fade-in-text">home</a></Link>*/}
            {/*</header>*/}
            <header className="about-project-header">
                <Link to="/" reloadDocument={true}><a
                    className="left-top-link fade-in">Home</a></Link>
                <div className="github-codepen-links"><a href="https://github.com/simpledznbymelissa"
                                                            className="about-contact-links fade-in"
                                                            style={{marginLeft: "1.5rem"}} target="_blank">Github</a>
                    <a href="https://codepen.io/SimpleDzn/pens/public"
                       className="about-contact-links fade-in" target="_blank">Codepen</a>
                    <a href="https://www.linkedin.com/in/melissa-brown-2b29b116/"
                       className="about-contact-links  fade-in" target="_blank">LinkedIn</a>
                </div>

            </header>

            <div className="about-video-container">
                <div className="set-max-width about-project-container">

                    <video
                        id="video1"
                        ref={videoRef}
                        className="about-video"
                        src={video}
                    ></video>

                    <div className="controlsContainer">
                        <div className="controls">
                            <img
                                onClick={revert}
                                className="controlsIcon"
                                alt=""
                                src="/backward-5.svg"
                            />
                            {playing ? (
                                <img
                                    onClick={() => videoHandler("pause")}
                                    className="controlsIcon--small"
                                    alt=""
                                    src="/pause.svg"
                                />
                            ) : (
                                <img
                                    onClick={() => videoHandler("play")}
                                    className="controlsIcon--small"
                                    alt=""
                                    src="/play.svg"
                                />
                            )}
                            <img
                                className="controlsIcon"
                                onClick={fastForward}
                                alt=""
                                src="/forward-5.svg"
                            />
                        </div>

                        <div className="timecontrols">
                            <p className="controlsTime">
                                {Math.floor(currentTime / 60) +
                                    ":" +
                                    ("0" + Math.floor(currentTime % 60)).slice(-2)}
                            </p>
                            <div className="time_progressbarContainer">
                                <div
                                    style={{width: `${progress}%`}}
                                    className="time_progressBar"
                                ></div>
                            </div>
                            <p className="controlsTime">
                                {Math.floor(videoTime / 60) +
                                    ":" +
                                    ("0" + Math.floor(videoTime % 60)).slice(-2)}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bottom-about-contact-links"><a href="https://github.com/simpledznbymelissa"
                                                           className="about-contact-links fade-in"
                                                           style={{marginLeft: "1.5rem"}} target="_blank">Github</a>
                <a href="https://codepen.io/SimpleDzn/pens/public"
                   className="about-contact-links fade-in" target="_blank">Codepen</a>
                <a href="https://www.linkedin.com/in/melissa-brown-2b29b116/"
                   className="about-contact-links  fade-in" target="_blank">LinkedIn</a>
            </div>
        </>
    );
}

export default About